<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    viewBox="0 0 1844.1 1391.1"
  >
    <g>
      <path
        :fill="colorTriangle"
        d="M978.3,394.5c2.5,4.3,5,8.6,7.5,13C905.1,267.8,829.4,136.7,750.9,0.6l-25.4,44.1L5.3,1292.1H56
		c7.3-12.5,19.2-33.3,29.5-51l-4.1,7c0.4,0.4,24.4-42.3,24.6-42.3C280.2,904.5,535.7,461.7,725.5,133c0,0,0,0,0,0
		c5.9-10.3,24.3-42,25.4-44c209,361.9,459.8,796.6,669.2,1159.1H246.6l-25.4,43.9h1275.3c-8.4-14.5-16.8-29.1-25.2-43.7
		c0.1,0.1-2.4-4.2-7-12.1c2.4,4.2,4.7,8.2,6.9,11.8 M1413.6,1236.8c2.3,4,4.5,7.8,6.5,11.3h-0.1
		C1417.9,1244.3,1415.8,1240.5,1413.6,1236.8L1413.6,1236.8z M1412.2,1234.3c-1-1.8-2.1-3.7-3.1-5.5l11.1,19.3h0L1412.2,1234.3
		L1412.2,1234.3z M415.4,669.1c0,0-309.9,536.8-309.9,536.8L415.4,669.1z"
      />
      <path
        :fill="colorR"
        d="M1776.5,324.5l-392.7,680.1c-8.5-14.7-17-29.4-25.5-44.2c134.8-233.5,277.5-480.8,392.6-680.1
		C1759.4,295,1767.9,309.7,1776.5,324.5z"
      />
      <path
        :fill="colorR"
        d="M1383.8,1004.6L1383.8,1004.6l-0.5,0.8c-8.5-14.7-17-29.4-25.5-44.2c0.2-0.3,0.3-0.5,0.5-0.8
		C1366.8,975.2,1375.3,989.9,1383.8,1004.6z"
      />
      <path
        :fill="colorR"
        d="M1715.5,218.8l-392.7,680.1c-8.5-14.8-17-29.5-25.5-44.2c135.5-234.8,278.3-482.3,392.6-680.1
		C1698.4,189.3,1706.9,204,1715.5,218.8z"
      />
      <path
        :fill="colorR"
        d="M1295.3,1134.8l-7.3,12.7l-17.7,30.7l-327-566.4l-25.4-43.9L750.7,278.4l-25.2-43.7c1.1-1.8,2.1-3.7,3.2-5.5
		c1.2-2,2.4-4.1,3.5-6.1c0.6-1,1.2-2.1,1.8-3.1c1.1-1.9,2.2-3.8,3.2-5.7l0.3-0.5c0.6-1,1.2-2,1.8-3c1.2-2,2.3-4,3.5-6
		c0.1-0.3,0.3-0.5,0.5-0.8c1-1.8,2-3.5,3-5.2c1.6-2.8,3.2-5.6,4.8-8.3c8,13.8,15.9,27.6,23.9,41.4h-1c0.6,1,1.2,2.1,1.8,3.1
		c61.3,106.1,126,218.3,192.3,333c8.4,14.6,16.9,29.2,25.4,43.9C1093.8,785.6,1196.8,964.1,1295.3,1134.8z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BrandIcon",
  props: ["colorTriangle", "colorR", "size"],
};
</script>
