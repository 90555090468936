import Vue from 'vue'
import App from './AppRouter.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMobileDetection from 'vue-mobile-detection'
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api';
import VueScreenSize from 'vue-screen-size';
import * as THREE from 'three';

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight,
});

// Pinia
const pinia = createPinia()
Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionAPI);

// Vue Mobile Detection
Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.use(VueMobileDetection);

// ScreenSize
Vue.use(VueScreenSize);

// Trois.js
Vue.use(THREE);

Vue.prototype.$loading = false;

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
