import gsap from "gsap";
const STRENGTH = 70;
var magnets;

export function initMagnetic() {
  magnets = document.querySelectorAll(".magnetic");
  magnets.forEach((magnet) => {
    magnet.addEventListener("mousemove", moveMagnet);
    magnet.addEventListener("mouseout", resetMagnet);
  });
}

export function destroyMagnetic() {
  magnets.forEach((magnet) => {
    magnet.removeEventListener("mousemove", moveMagnet);
    magnet.removeEventListener("mouseout", resetMagnet);
  });
}

function moveMagnet(event) {
  var magnetButton = event.currentTarget;
  var bounding = magnetButton.getBoundingClientRect();

  let xTo = gsap.quickTo(magnetButton, "x", {
      duration: 1,
      ease: "Power4.easeOut",
    }),
    yTo = gsap.quickTo(magnetButton, "y", {
      duration: 1,
      ease: "Power4.easeOut",
    });
  //console.log(magnetButton, bounding)

  xTo(
    ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) *
      STRENGTH
  );
  yTo(
    ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) *
      STRENGTH
  );

  //magnetButton.style.transform = 'translate(' + (((( event.clientX - bounding.left)/(magnetButton.offsetWidth))) - 0.5) * strength + 'px,'+ (((( event.clientY - bounding.top)/(magnetButton.offsetHeight))) - 0.5) * strength + 'px)';
}

function resetMagnet(event){
  gsap.to(event.currentTarget, {
    x: 0,
    y: 0,
    ease: "Power4.easeOut",
    duration: 1,
  });
}