<template>
  <div class="main-div disclaimer">
    <!-- <div class="disclaimer noise" /> -->
    <div class="textblock disclaimer">
      <div class="disclaimer icon">
        <BrandIconLoading
          colorTriangle="var(--dark-textcolor)"
          size="calc(3rem + 2vmin)"
        />
      </div>
      <div class="spacing" v-if="this.$vssWidth < 800" />
      <div class="disclaimer" v-if="this.$vssWidth < 800">
        <span>This App is cooler on Desktop!</span>
      </div>
      <div class="spacing" />
      <p class="centeredText">
        <strong class="disclaimer marked-text">{{ loadingNum }}%</strong><br />
      </p>
    </div>
  </div>
</template>

<script>
import BrandIconLoading from './svg/BrandIconLoading.vue';
export default {
    components:{
        BrandIconLoading,
    },
    data(){
      return{
        loadingNum: 0
      };
    },
    mounted(){
      this.intervalLoad = setInterval(() => {
        if(this.loadingNum != 100){
          this.loadingNum++;
        } else{
          clearInterval(this.intervalLoad);
        }
      }, 20);
    }
};
</script>
<style lang="scss">
.disclaimer.main-div {
  background-color: var(--dark-backgroundcolor);
}
.disclaimer.textblock {
  position: absolute;
  color: var(--dark-accentcolor2_darker);
  z-index: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.disclaimer.icon {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: "1rem";
  width: "1rem";
}
.disclaimer.marked-text {
  color: var(--dark-textcolor);
}
</style>