<template>
  <div :style="cssVars">
    <v-layout class="navigation wrapper">
      <v-layout column align-self-start>
        <div
          id="BrandIcon"
          @mouseenter="brandLogoVisibility = true"
          @mouseleave="brandLogoVisibility = false"
        >
          <BrandIcon
            :colorTriangle="iconColorTriangle"
            :colorR="iconColorR"
            size="calc(2rem + 2vmin)"
            v-on:click.native="gotoHome"
          />
        </div>
        <transition name="fade" v-on:enter="enter">
          <!-- Change v-if to "brandLogoVisibility" to see Logo again -->
          <div key="1" id="BrandLogo" v-if="brandLogoVisibilityFalse">
            <BrandLogo :color="iconColorTriangle" size="27rem" />
          </div>
        </transition>
      </v-layout>
      <v-layout column align-self-end>
        <v-layout justify-end id="Menu">
          <v-layout column class="navtextcolumn"
            ><span class="navigation item navtext" v-on:click="gotoHome"
              ><router-link to="/" class="navigation link underline"
                >Home</router-link
              ></span
            ></v-layout
          >
          <v-layout column class="navtextcolumn"
            ><span class="navigation item navtext"
              ><router-link to="/categories" class="navigation link underline"
                >Cases</router-link
              ></span
            ></v-layout
          >
          <v-layout column class="navtextcolumn"
            ><span class="navigation item navtext"
              ><router-link to="/contact" class="navigation link underline"
                >Offers & Plans</router-link
              ></span
            ></v-layout
          >
          <v-layout column class="navdropdowncolumn">
            <div
              class="navigation item navdropdown"
              key="1"
              @click="dropdownVisibility = !dropdownVisibility"
            >
              <MenuToX
                size="calc(1.8rem + 1.8vmin)"
                strokeWidth="3"
                strokeLinecap="square"
                :color="iconColorTriangle"
              />
            </div>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
    <transition name="fadeInSlide" v-on:enter="enter">
      <div v-if="dropdownVisibility" key="1" id="Dropdown" class="dropdownmenu">
        <div>
          <div class="dropdownmenu item" v-on:click="gotoHome">
            <router-link to="/" class="navigation link">Home</router-link>
          </div>
        </div>
        <div>
          <div class="dropdownmenu item" v-on:click="resetDropDown()">
            <router-link to="/categories" class="navigation link"
              >Cases</router-link
            >
          </div>
        </div>
        <div>
          <div class="dropdownmenu item" v-on:click="resetDropDown()">
            <router-link to="/contact" class="navigation link"
              >Offers & Plans</router-link
            >
          </div>
        </div>
      </div>
    </transition>
    <div
      id="fullpagenav-container"
      class="fullpagenav-container center-vertical"
    >
      <div class="fullpagenav-path center relative" />
      <div
        id="fullpagenav-pathstroke"
        class="fullpagenav-pathstroke center-horizontal relative"
      />
    </div>
  </div>
</template>

<script>
import BrandIcon from "./svg/BrandIcon.vue";
import BrandLogo from "./svg/BrandLogo.vue";
import MenuToX from "./svg/MenuToX.vue";

export default {
  name: "Navigation",

  components: {
    BrandIcon,
    BrandLogo,
    MenuToX,
  },
  data() {
    return {
      brandLogoVisibility: false,
      brandLogoVisibilityFalse: false,
      dropdownVisibility: false,
    };
  },
  methods: {
    gotoHome() {
      this.$emit("gotoHome");
      this.resetDropDown();
    },
    resetDropDown() {
      this.dropdownVisibility = false;
    },
  },
  props: ["color", "hoverColor", "iconColorTriangle", "iconColorR"],
  computed: {
    cssVars() {
      return {
        "--current-color": this.color,
        "--current-hover-color": this.hoverColor,
      };
    },
  },
};
</script>
