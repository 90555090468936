<template>
  <div class="main-div loading-screen">
    <div class="center absolute">
      <BrandIconLoading
        colorTriangle="var(--dark-textcolor)"
        size="calc(3rem + 2vmin)"
      />
    </div>
  </div>
</template>

<script>
import BrandIconLoading from "./svg/BrandIconLoading.vue";
export default {
  components: {
    BrandIconLoading,
  },
};
</script>
<style lang="scss">
.loading-screen.main-div {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100 !important;
  background-color: var(--dark-backgroundcolor);
}
.fadeInOut-enter-active {
  animation: fadeInAnim 0.2s forwards;
}
.fadeInOut-leave-active {
  animation: fadeOutAnim 0.2s forwards;
}
@keyframes fadeInAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutAnim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>