<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="size"
    viewBox="0 0 1908 1549"
    xml:space="preserve"
  >
  <g id="Layer_1" data-name="Layer 1" >
    <path :fill="color" d="M83.04,22.36l.13,78.57h13.03V42l-13.16-19.64Zm36.98,44.02l3.16-1.09c3.71-1.28,6.89-3.58,9.46-6.85l.31-.39c.3-.4,.61-.83,.94-1.31,1-1.51,1.84-3.09,2.48-4.7,.44-1.1,.79-2.21,1.06-3.33l-12.19-18.18v.23l-9.62-14.35c-1.82-.33-3.69-.49-5.6-.49h-17.67l8.66,12.89h9.29c2.75,0,5.28,.75,7.53,2.22,.78,.49,1.57,1.12,2.32,1.84,2.82,2.71,4.25,6.02,4.25,9.83s-1.43,7.11-4.25,9.84c-.74,.71-1.53,1.33-2.33,1.84-2.27,1.46-4.84,2.15-7.6,2.08h-11.53l29.99,44.47h16.04l-24.7-34.55Z"/>
    <path :fill="color" d="M155.98,42.2c-3.35,4.87-5.05,10.29-5.05,16.09s1.7,11.35,5.05,16.23c2.07,3.02,4.53,5.56,7.33,7.61,0,.02,.03,.03,.05,.04,.1,.08,.34,.26,.73,.52,.14,.1,.31,.21,.49,.32,.28,.19,.61,.39,.98,.61,2.45,1.43,6.8,3.49,12.63,4.31h.03c.78,.08,1.55,.11,2.33,.11,6.25,0,12.22-2.09,17.8-6.22v14.35c-5.56,2.97-11.47,4.52-17.66,4.62-4.11,0-8.21-.64-12.23-1.93,0,0-6.23-1.94-9.97-4.62-5.72-3.67-10.46-8.53-14.11-14.48-4.02-6.55-6.06-13.78-6.06-21.47s2.04-14.78,6.06-21.34c0,0,2.32-3.85,6.06-7.81l.04-.05c.99-1.04,2.07-2.09,3.23-3.08,1.1-.93,2.04-1.63,2.39-1.89,.85-.63,1.66-1.18,2.4-1.65,0,0,4.54-3.29,9.97-4.62,4.02-1.28,8.1-1.92,12.19-1.93,6.21,.11,12.13,1.66,17.69,4.63v14.35c-5.58-4.14-11.55-6.23-17.8-6.23-1.3,0-2.61,.08-3.91,.24,0,0-6.46,.86-10.59,3.79-.23,.13-.45,.27-.67,.42-3.68,2.29-6.82,5.34-9.4,9.08Z"/>
    <path :fill="color" d="M271.25,20.59c-7.81-8.22-18.74-12.37-32.56-12.25h-18.25l17.12,14.04h1.12c9.46,0,17.07,2.88,22.61,8.58,5.57,5.74,8.4,13.72,8.4,23.68,0,1.24-.05,2.55-.14,3.92l-.02,.66c-.91,8.51-4.13,15.28-9.56,20.14-5.37,4.81-12.49,7.24-21.15,7.24h-1.54l-17.27,14.19h18.67c13.8,0,24.76-4.12,32.58-12.26,7.79-8.1,11.74-19.51,11.74-33.89s-3.95-25.83-11.75-34.05Zm-60.21-3.58V91.67l13.03-10.67V27.68l-13.03-10.67Z"/>
    <path :fill="color" d="M294.89,15.92l13.05,12.75h26.4V15.92h-39.45Zm12.92,71.98l-13.09,12.89h39.62v-12.89h-26.53Zm-9.67-36.62v-19.06l-13.03-12.83V97.08l13.03-12.73v-20.04h31.02v-13.03h-31.02Z"/>
    <path :fill="color" d="M389.36,77.58c.39,27.65-44.81,30.39-55.96,9.83l10.05-6.94c4.15,5.22,9.93,7.85,17.24,7.85,4.54,0,8.3-.87,11.2-2.58,4.13-2.45,5-5.64,5-7.88,.02-5.9-5.76-9.75-11.02-12.84-3.01-1.66-13.14-6.75-15.78-8.22-2.3-1.28-7.67-4.85-9.26-7.2-.93-1.25-1.78-2.9-2.54-4.91-5.33-16.18,8.58-29.19,24.78-28.77,9.69,.09,18.32,3.24,22.37,11.98l-8.91,6.26c-4.41-6.73-16.21-8.19-22.69-3.33-5.17,3.79-5.65,9.83-1.9,13.36,3.49,3.32,8.21,4.86,12.59,6.98l.95,.43,11.23,5.9c7.87,5.14,12.65,10.56,12.65,20.08Z"/>
    <path :fill="color" d="M487.76,55.61h-26.43l-7.29,10.93h20.33l-.15,2.64c-.31,5.86-2.78,10.6-7.33,14.08-4.39,3.35-10.05,5.05-16.83,5.05-3.31,0-6.53-.45-9.59-1.35l-7.2,10.87c5.08,1.96,10.44,2.95,15.96,2.95,7.18,0,13.75-1.56,19.5-4.66,5.7-3.06,10.38-7.75,13.89-13.92,3.54-6.2,5.34-13.55,5.34-21.82,0-1.5-.07-3.09-.2-4.77Zm-24.29-36.59c-4.96-1.97-9.94-3.02-14.83-3.11-7.61,.01-14.74,1.94-21.21,5.74-6.49,3.81-11.69,9.03-15.46,15.52-3.76,6.48-5.67,13.63-5.67,21.25s1.82,14.39,5.4,20.63c1.15,2.01,3.59,5.93,7.75,9.89,5.02,4.78,10.23,7.43,13.82,8.89,2.4-3.62,4.8-7.25,7.2-10.87-1.08-.36-7.2-1.22-13.8-8.35-2.29-2.47-3.94-5.4-3.94-5.4-2.53-4.46-3.82-9.44-3.82-14.79,0-8.41,3.04-15.58,9.02-21.31,5.95-5.7,12.84-8.59,20.46-8.59,6.89,0,13.61,2.56,20.01,7.61l6.86-10.23c-3.55-2.86-7.51-5.17-11.79-6.88Z"/>
    <rect :fill="color" x="390.89" y="16.91" width="13.17" height="10.77"/>
    <polygon :fill="color" points="404.06 32.99 404.06 100.93 390.89 100.93 390.89 32.99 397.48 39.07 404.06 32.99"/>
    <polygon :fill="color" points="503.51 53.11 503.51 100.93 490.48 100.93 490.48 40.3 503.51 53.11"/>
    <polygon :fill="color" points="211.04 8.33 211.04 82.99 224.07 72.32 224.07 19 211.04 8.33"/>
    <polygon :fill="color" points="211.04 26.13 211.04 100.79 224.07 90.11 224.07 36.8 211.04 26.13"/>
    <polyline :fill="color" points="300.42 87.9 287.33 100.79 326.95 100.79 326.95 87.9"/>
    <polyline :fill="color" points="328.01 28.74 328.01 15.85 288.39 15.85 301.48 28.74"/>
    <path :fill="color" d="M364.53,51.17c-1.27-.56-2.96-1.35-3.97-1.81l3.97,1.81Z"/>
    <polygon :fill="color" points="557.6 15.92 557.6 76.46 544.57 63.64 544.57 15.92 557.6 15.92"/>
  </g>
  <g id="Layer_2" data-name="Layer 2">
    <polygon :fill="color" points="490.48 17.11 557.6 82.4 557.6 99.85 490.48 34.17 490.48 17.11"/>
  </g>
  <g id="Layer_4" data-name="Layer 4">
    <polygon :fill="color" points="35.3 19.66 39.3 10.31 78.11 100.93 68.8 100.93 35.3 19.66"/>
    <polygon :fill="color" points="28.91 32.99 35.85 49.99 14.66 100.56 0 100.56 28.91 32.99"/>
    <polygon :fill="color" points="41.78 4.5 83.13 100.93 87.21 100.93 43.93 0 41.78 4.5"/>
  </g>
  <g id="Layer_5" data-name="Layer 5">
    <polygon :fill="color" points="35.3 93.59 40.4 81.82 61.04 81.82 66.18 93.59 35.3 93.59"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: "BrandLogo",
  props: ["color", "size"],
};
</script>

<style type="text/css">
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
</style>