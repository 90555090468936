<template>
  <div id="app">
    <div id="Noise" class="color-dodge" />
    <div id="Noise" class="lighten" />
    <transition name="fadeIn" v-on:enter="enter">
      <nav key="1" id="Navigation" v-if="navigationVisibility == 0">
        <Navigation
          ref="nav"
          :color="lightTextcolor"
          :iconColorTriangle="lightTextcolor"
          :iconColorR="lightTextcolor"
          @gotoHome="setGotoHome"
        />
      </nav>
      <nav key="2" id="Navigation" v-if="navigationVisibility == 1">
        <Navigation
          ref="nav"
          :color="darkTextcolor"
          :iconColorTriangle="darkTextcolor"
          :iconColorR="darkAccentColor"
          @gotoHome="setGotoHome"
        />
      </nav>
      <nav
        key="3"
        id="Navigation"
        v-if="navigationVisibility == 2"
        :style="
          'background-color:' +
          currentNavigationCustomColors.backgroundColor +
          ';backdrop-filter: blur(' +
          currentNavigationCustomColors.blur +
          'px);'
        "
      >
        <Navigation
          ref="nav"
          :color="currentNavigationCustomColors.color"
          :iconColorTriangle="currentNavigationCustomColors.iconColorTriangle"
          :iconColorR="currentNavigationCustomColors.iconColorR"
          @gotoHome="setGotoHome"
        />
      </nav>
      <nav key="4" id="Navigation" v-if="navigationVisibility == 3">
        <Navigation
          ref="nav"
          :color="nightTitlecolor"
          :iconColorTriangle="nightAccentcolor"
          :iconColorR="nightAccentcolor"
          @gotoHome="setGotoHome"
        />
      </nav>
      <nav key="5" id="Navigation" v-if="navigationVisibility == 4">
        <Navigation
          ref="nav"
          :color="nightTitlecolor"
          :iconColorTriangle="nightNeoncyan"
          :iconColorR="nightNeoncyan"
          @gotoHome="setGotoHome"
        />
      </nav>
    </transition>
    <transition name="fadeInOut" v-on:enter="enter">
      <MobileDisclaimer id="MobileDisclaimer" v-if="disclaimerVisibility" />
      <LoadingScreen style="z-index: 100" v-if="isLoading" />
    </transition>
    <router-view
      ref="view"
      id="Content"
      @themeModeChanged="changeNavigationTheme"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Navigation from "./components/Navigation.vue";
import MobileDisclaimer from "./components/MobileDisclaimer.vue";
import LoadingScreen from "./components/LoadingScreen.vue";
import { useLoadingStore } from "./loading";
import { initMagnetic, destroyMagnetic } from "./scripts/magnetic/magnetic.js";

export default {
  name: "AppRouter",

  components: {
    Navigation,
    MobileDisclaimer,
    LoadingScreen,
  },
  data() {
    return {
      loadingStore: useLoadingStore(),
      disclaimerVisibility: true,
      navigationVisibility: 0,
      currentNavigationCustomColors: {
        color: null,
        iconColorTriangle: null,
        iconColorR: null,
        backgroundColor: null,
      },
      lightTextcolor: "var(--default-textcolor)",
      lightBackgroundcolor: "var(--default-backgroundcolor)",
      darkTextcolor: "var(--dark-textcolor)",
      darkAccentColor: "var(--dark-accentcolor)",
      darkAccentColor2: "-var(--dark-accentcolor2)",
      darkAccentColor2_lighter: "var(--dark-accentcolor2_lighter)",
      darkAccentColor2_darker: "var(--dark-accentcolor2_darker)",
      darkAccentColor3: "var(--dark-accentcolor3)",
      darkAccentColor4: "var(--dark-accentcolor4)",
      darkBackgroundcolor: "var(--dark-background)",
      nightTitlecolor: "var(--night-titlecolor)",
      nightTextcolor: "var(--night-textcolor)",
      nightAccentcolor: "var(--dark-accentcolor)",
      nightAccentcolor2: "var(--dark-textcolor)",
      nightBackgroundcolor: "var(--night-backgroundcolor)",
      nightNeoncyan: "var(--night-neoncyan)",
    };
  },
  computed: {
    isLoading() {
      return this.loadingStore.loading;
    },
  },
  methods: {
    changeNavigationTheme(values) {
      switch (values[0]) {
        case "dark":
          this.navigationVisibility = 1;
          break;
        case "custom":
          this.navigationVisibility = 2;
          this.currentNavigationCustomColors = values[1];
          break;
        case "night":
          this.navigationVisibility = 3;
          break;
        case "nightCyan":
          this.navigationVisibility = 4;
          break;
        default:
          this.navigationVisibility = 0;
      }
    },
    getRouterTransition() {
      return "router-view";
    },
    setGotoHome() {
      this.$refs.view.gotoHome();
    },
  },
  created() {
    setTimeout(() => {
      this.disclaimerVisibility = false;
    }, 3000);
  },
  mounted() {
    setTimeout(() => {
      initMagnetic();
    }, 500);
  },
  watch: {
    $route(to, from) {
      destroyMagnetic();
      this.$nextTick(() => {
        setTimeout(() => {
          initMagnetic();
        }, 500);
      });
    },
  },
};
</script>

<style>
@import "./approuter-styling.min.css";
</style>
