<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    viewBox="0 0 226 200"
    class="loader-triangle-7"
  >
    <defs>
      <linearGradient
        x1="114.720775%"
        y1="181.283245%"
        x2="39.5399306%"
        y2="100%"
        id="linearGradient-1"
      >
        <stop :stop-color="colorTriangle" offset="0%" />
        <stop :stop-color="colorTriangle" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Artboard"
      fill-rule="nonzero"
      stroke="url(#linearGradient-1)"
      stroke-width="7"
    >
      <path
      fill="transparent"
        d="M113,5.08219117 L4.28393801,197.5 L221.716062,197.5 L113,5.08219117 Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BrandIcon",
  props: ["colorTriangle", "size"],
};
</script>
<style type="text/css">
.loader-triangle-7 #Artboard {
  stroke-dasharray: 0 226;
  animation: anm-tr7-dash 1s ease-in-out alternate infinite;
}

@keyframes anm-tr7-dash {
  from {
    stroke-dasharray: 0 226;
  }
  to {
    stroke-dasharray: 226 0;
  }
}
</style>
