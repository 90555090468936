<template>
        <button class="menu-to-x-svg" onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))" aria-label="Main Menu">
      <svg :width="size" viewBox="0 0 100 100">
        <path class="menu-to-x-line menu-to-x-line1" :stroke="color"
      :stroke-width="strokeWidth"
      :stroke-linecap="strokeLinecap" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
        <path class="menu-to-x-line menu-to-x-line2" :stroke="color"
      :stroke-width="strokeWidth"
      :stroke-linecap="strokeLinecap" d="M 20,50 H 80" />
        <path class="menu-to-x-line menu-to-x-line3" :stroke="color"
      :stroke-width="strokeWidth"
      :stroke-linecap="strokeLinecap" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
      </svg>
    </button>
  </template>
  <script>
  export default {
    name: "Menu",
    props: ["color", "strokeWidth", "strokeLinecap", "size"]
  };
  </script>
  <style lang="scss">
.menu-to-x-svg {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.menu-to-x-line {
  fill: none;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.menu-to-x-line1 {
  stroke-dasharray: 60 207;
}
.menu-to-x-line2 {
  stroke-dasharray: 60 60;
}
.menu-to-x-line3 {
  stroke-dasharray: 60 207;
}
.opened .menu-to-x-line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}
.opened .menu-to-x-line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
}
.opened .menu-to-x-line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

</style>