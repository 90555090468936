import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/categories/',
    name: 'categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/CasesCategoriesView.vue')
  },
  {
    path: '/cases/:category',
    name: 'cases',
    component: () => import(/* webpackChunkName: "cases" */ '../views/CasesView.vue'),
    props: (route) => ({
      category: route.params.category,
    }),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/cases/project/avalonia',
    name: 'cases_avalonia',
    component: () => import(/* webpackChunkName: "gallery_avalonia" */ '../views/DevelopmentDisclaimer.vue')
  },
  {
    path: '/cases/project/gkc',
    name: 'cases_gkc',
    component: () => import(/* webpackChunkName: "gallery_gkc" */ '../components/cases/items/GKC.vue')
  },
  {
    path: '/cases/project/eso-stats',
    name: 'cases_eso-stats',
    component: () => import(/* webpackChunkName: "gallery_gkc" */ '../components/cases/items/ESOStats.vue')
  },
  {
    path: '/cases/project/msag',
    name: 'cases_msag',
    component: () => import(/* webpackChunkName: "gallery_msag" */ '../components/cases/items/Msag.vue')
  },
  {
    path: '/legacy/story',
    name: 'legacy_story',
    component: () => import('../components/legacy/Old_AboutMe.vue')
  },
  {
    path: '/legacy/mediadesign',
    name: 'legacy_mediadesign',
    component: () => import('../components/legacy/Old_MediaDesign.vue')
  },
  {
    path: '/legacy/softwaredev',
    name: 'legacy_softwaredev',
    component: () => import('../components/legacy/Old_Night.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

import { useLoadingStore } from "../loading";
router.beforeResolve((to, from, next) => {
  // Start the loading indicator.
  if (from.name) {
    const loadingStore = useLoadingStore();
    loadingStore.setLoading(true);
  }
  setTimeout(() => {
    next();
  }, 200);
});
router.afterEach((to, from) => {
  // Complete the loading indicator.
  const loadingStore = useLoadingStore();
  setTimeout(() => {
    loadingStore.setLoading(false);
  }, 800);
});

export default router
